.PaletteSelector__item {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    padding: 10px;
}

.PaletteSelector__item:hover {
    background-color: #f4f4f4 !important;
}

.PaletteSelector__item--active:hover {
    background-color: transparent !important;
}

@media screen and (min-width: 1280px) {

    .PaletteSelector {
        display: flex;
        flex-direction: column;
    }

    .PaletteSelector__item {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
        padding: 10px;
    }

    .PaletteSelector__item__icon {
        width: 80px;
    }

    .PaletteSelector__item span {
        font-family: 'Inter';
        font-size: 16px;
    }
}

@media screen and (max-width: 1280px) {

    .PaletteSelector {
        display: flex;
        flex-direction: row;
        overflow-x: scroll;
    }

    .PaletteSelector__item {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        padding: 10px;
    }

    .PaletteSelector__item__icon {
        width: 40px;
    }

    .PaletteSelector__item span {
        font-family: 'Inter';
        font-size: 14px;
    }
}