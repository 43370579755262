.SmoothImageStep {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.SmoothImageStep__title {
    padding: 20px;
}


@media screen and (min-width: 1280px) {
    .SmoothImageStep__body {
        display: grid;
        padding: 0 60px 0 60px;
        grid-template-columns: 2fr 0.8fr;
        grid-gap: 0;
    }

    .SmoothImageStep__preview {
        width: 100%;
        height: 500px;
    }

    .SmoothImageStep__preview__image {
        max-width: 100%;
        max-height: 500px;
        margin: auto;
    }

    .SmoothImageStep__config {
        padding: 0 10px 10px 10px;
        display: flex;
        gap: 20px;
        flex-direction: column;
    }

    .SmoothImageStep__difficulty {
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 20px 10px 0 0;
    }

    .SmoothImageStep__difficulty__slider {
        padding-left: 10px;
    }
}

@media screen and (max-width: 1280px) {
    .SmoothImageStep__body {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
    
    .SmoothImageStep__preview {
        width: 100%;
        padding: 0 20px;
    }

    .SmoothImageStep__preview__image {
        max-width: 100%;
    }

    .SmoothImageStep__config {
        padding: 0 20px;
        display: flex;
        gap: 5px;
        flex-direction: column;
    }

    .SmoothImageStep__difficulty {
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 0;
    }

    .SmoothImageStep__difficulty__slider {
        padding: 0 20px 20px 20px;
        margin-bottom: 30px;
    }
}
