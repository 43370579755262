

@media screen and (min-width: 1280px) {
    .Wizard {
        position: relative;
        width: 80%;
        height: 800px;
        margin: 10px auto;
        background-color: #fff;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
} 

@media screen and (max-width: 1280px) {
    .Wizard {
        position: relative;
        width: 100%;
        background-color: #fff;
        box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
        /* border-radius: 4px; */
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
}