.Header {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: white;
    padding: 10px 30px;
    box-shadow: rgba(0, 0, 0, 0.11) 0px 1px 0px 0px;
    justify-content: space-between;
    border-bottom: 1px solid rgb(232, 233, 235);
}

@media screen and (max-width: 1280px) {
    .Header {
        padding: 10px 10px;
    }
}