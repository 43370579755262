.ActionBar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 60px 30px 60px;
}


@media screen and (max-width: 1280px) {
    .ActionBar {
        padding: 20px;
    }
}