.user-photo-container {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    cursor: pointer;
    gap: 5px;
}

.my-orders {
    font-size: 12px;
    font-family: 'Inter';
    font-weight: bold;
    display: block;
}

.user-photo {
    width: 40px;
    height: 40px;
    border-radius: 40px;
}

@media screen and (max-width: 1280px) {
    .user-photo-container {
        flex-direction: column;
        gap: 5px;
    }

    .user-photo {
        width: 25px;
        height: 25px;
        border-radius: 20px;
    }

    .my-orders {
        font-size: 12px;
        font-family: 'Inter';
        font-weight: bold;
        display: block;
    }
}