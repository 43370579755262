.DownloadButton {
    background-color: #FF9800;
    color: #fff;
    border: none;
    outline: none;
    border-radius: 8px;
    font-size: 14px;
    padding: 10px 12px;
    font-family: 'Inter', sans-serif;
    font-weight: bold;
    margin: 5px;
}

.DownloadButton:hover {
    background-color: #f0ab00;
}

.DownloadButton--deactived {
    background-color: gray !important;
}

.DownloadButton--deactived:hover {
    background-color: gray !important;
}

@media screen and (max-width: 1280px) {
    .DownloadButton {
        margin: 0;
    }
}