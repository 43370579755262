


@media screen and (min-width: 1280px) {
    .PickImageStep__title {
        padding: 50px 60px;
    }

    .PickImageStep__pick-image {
        border: 1px dashed #000; 
        height: 500px; 
        width: 80%;
        margin: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .PickImageStep {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .PickImageStep__preview {
        height: 500px; 
        width: 80%; 
        margin: auto; 
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .PickImageStep__preview__container {
        position: relative;
        display: inline-block;
        height: 500px;
    }

    .PickImageStep__preview__image {
        height: 100%;
    }
    
}

@media screen and (max-width: 1280px) {

    .PickImageStep {
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding: 0 20px;
    }

    .PickImageStep__title {
        padding: 20px 0;
    }

    .PickImageStep__pick-image {
        display: block;
        text-align: center;
    }

    .PickImageStep__preview {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .PickImageStep__preview__container {
        position: relative;
        width: 100%;
    }

    .PickImageStep__preview__image {
        width: 100%;
    }
}