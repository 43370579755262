.DownloadStep {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}



.DownloadStep__form {
    display: flex;
    flex-direction: column;
    gap: 10px; 
}

@media screen and (max-width: 1280px) {
    .DownloadStep__preview {
        display: none;
    }
} 

@media screen and (min-width: 1280px) {
    .DownloadStep__preview {
        display: 'block';
    }

    .DownloadStep__preview img {
        width: 200px;
        transform: rotate(-20deg);
        height: 150px;
        object-fit: cover;
    }
} 

@media screen and (min-width: 1280px) {
    .DownloadStep__body {
        display: flex;
        margin: auto;
        padding: 0 60px 0 60px;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        align-content: center;
        gap: 100px;
    }    
}

@media screen and (max-width: 1280px) {
    .DownloadStep__body {
        display: flex;
        margin: auto;
        padding: 20px;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        align-content: center;
        gap: 100px;
    }    
}