@import url(https://fonts.googleapis.com/css2?family=Varela+Round&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap);
html, body, #root{
  height: 100% !important;
  background-color: #F6F6F6 !important;
}

body {
  margin: 0;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
  background-color: #F6F6F6;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.user-photo-container {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    cursor: pointer;
    grid-gap: 5px;
    gap: 5px;
}

.my-orders {
    font-size: 12px;
    font-family: 'Inter';
    font-weight: bold;
    display: block;
}

.user-photo {
    width: 40px;
    height: 40px;
    border-radius: 40px;
}

@media screen and (max-width: 1280px) {
    .user-photo-container {
        flex-direction: column;
        grid-gap: 5px;
        gap: 5px;
    }

    .user-photo {
        width: 25px;
        height: 25px;
        border-radius: 20px;
    }

    .my-orders {
        font-size: 12px;
        font-family: 'Inter';
        font-weight: bold;
        display: block;
    }
}
.Header {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: white;
    padding: 10px 30px;
    box-shadow: rgba(0, 0, 0, 0.11) 0px 1px 0px 0px;
    justify-content: space-between;
    border-bottom: 1px solid rgb(232, 233, 235);
}

@media screen and (max-width: 1280px) {
    .Header {
        padding: 10px 10px;
    }
}

.breadcrumb__item__index--active {
    background-color: #1976D2 !important;
}

@media screen and (min-width: 1280px) {
    .breadcrumb__item__text {
        margin-left: 10px;
        font-family: 'Inter';
        font-size: 18px;
    }

    .breadcrumb__item {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin: 10px;
    }

    .breadcrumb__item__index {
        background-color: #C4C4C4;
        font-family: 'Inter';
        font-size: 18px;
        color: white;
        width: 30px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        border-radius: 100%;
    }

}

@media screen and (max-width: 1280px) {
    .breadcrumb__item__text {
        margin-left: 5px;
        font-family: 'Inter';
        font-size: 14px;
        text-align: center;
    }

    .breadcrumb__item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .breadcrumb__item__index {
        background-color: #C4C4C4;
        font-family: 'Inter';
        font-size: 18px;
        color: white;
        width: 30px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        border-radius: 100%;
    }

}
.breadcrumb {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
}

.breadcrumb__arrow {
    width: 30px;
    margin-left: 10px;
    margin-right: 10px;
}

@media screen and (max-width: 1280px) {
    .breadcrumb {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .breadcrumb__arrow {
        width: 20px;
        margin-left: 10px;
        margin-right: 10px;
    }
}
.ActionBar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 60px 30px 60px;
}


@media screen and (max-width: 1280px) {
    .ActionBar {
        padding: 20px;
    }
}
.Gallery {
    display: flex;
    flex-direction: row;
    grid-gap: 5px;
    gap: 5px;
    align-items: center;
    overflow-x: auto;
    flex-wrap: nowrap;
}

.Gallery__item {
    border: 1px solid rgba(0, 0, 0, 0.5);
}

.Gallery__item__img {
    width: 150px;
    height: 150px;
    object-fit: cover;
}

@media screen and (max-width: 1280px) {
    .Gallery__item__img {
        width: 100px;
        height: 100px;
    }
}



@media screen and (min-width: 1280px) {
    .PickImageStep__title {
        padding: 50px 60px;
    }

    .PickImageStep__pick-image {
        border: 1px dashed #000; 
        height: 500px; 
        width: 80%;
        margin: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .PickImageStep {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .PickImageStep__preview {
        height: 500px; 
        width: 80%; 
        margin: auto; 
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .PickImageStep__preview__container {
        position: relative;
        display: inline-block;
        height: 500px;
    }

    .PickImageStep__preview__image {
        height: 100%;
    }
    
}

@media screen and (max-width: 1280px) {

    .PickImageStep {
        display: flex;
        flex-direction: column;
        grid-gap: 20px;
        gap: 20px;
        padding: 0 20px;
    }

    .PickImageStep__title {
        padding: 20px 0;
    }

    .PickImageStep__pick-image {
        display: block;
        text-align: center;
    }

    .PickImageStep__preview {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .PickImageStep__preview__container {
        position: relative;
        width: 100%;
    }

    .PickImageStep__preview__image {
        width: 100%;
    }
}
.PaletteSelector__item {
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
    padding: 10px;
}

.PaletteSelector__item:hover {
    background-color: #f4f4f4 !important;
}

.PaletteSelector__item--active:hover {
    background-color: transparent !important;
}

@media screen and (min-width: 1280px) {

    .PaletteSelector {
        display: flex;
        flex-direction: column;
    }

    .PaletteSelector__item {
        display: flex;
        flex-direction: row;
        align-items: center;
        grid-gap: 10px;
        gap: 10px;
        padding: 10px;
    }

    .PaletteSelector__item__icon {
        width: 80px;
    }

    .PaletteSelector__item span {
        font-family: 'Inter';
        font-size: 16px;
    }
}

@media screen and (max-width: 1280px) {

    .PaletteSelector {
        display: flex;
        flex-direction: row;
        overflow-x: scroll;
    }

    .PaletteSelector__item {
        display: flex;
        flex-direction: column;
        align-items: center;
        grid-gap: 10px;
        gap: 10px;
        padding: 10px;
    }

    .PaletteSelector__item__icon {
        width: 40px;
    }

    .PaletteSelector__item span {
        font-family: 'Inter';
        font-size: 14px;
    }
}
.SmoothImageStep {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.SmoothImageStep__title {
    padding: 20px;
}


@media screen and (min-width: 1280px) {
    .SmoothImageStep__body {
        display: grid;
        padding: 0 60px 0 60px;
        grid-template-columns: 2fr 0.8fr;
        grid-gap: 0;
    }

    .SmoothImageStep__preview {
        width: 100%;
        height: 500px;
    }

    .SmoothImageStep__preview__image {
        max-width: 100%;
        max-height: 500px;
        margin: auto;
    }

    .SmoothImageStep__config {
        padding: 0 10px 10px 10px;
        display: flex;
        grid-gap: 20px;
        gap: 20px;
        flex-direction: column;
    }

    .SmoothImageStep__difficulty {
        display: flex;
        flex-direction: column;
        grid-gap: 10px;
        gap: 10px;
        padding: 20px 10px 0 0;
    }

    .SmoothImageStep__difficulty__slider {
        padding-left: 10px;
    }
}

@media screen and (max-width: 1280px) {
    .SmoothImageStep__body {
        display: flex;
        flex-direction: column;
        grid-gap: 20px;
        gap: 20px;
    }
    
    .SmoothImageStep__preview {
        width: 100%;
        padding: 0 20px;
    }

    .SmoothImageStep__preview__image {
        max-width: 100%;
    }

    .SmoothImageStep__config {
        padding: 0 20px;
        display: flex;
        grid-gap: 5px;
        gap: 5px;
        flex-direction: column;
    }

    .SmoothImageStep__difficulty {
        display: flex;
        flex-direction: column;
        grid-gap: 10px;
        gap: 10px;
        padding: 0;
    }

    .SmoothImageStep__difficulty__slider {
        padding: 0 20px 20px 20px;
        margin-bottom: 30px;
    }
}

.DownloadButton {
    margin: 0 !important;
}
.DownloadStep {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}



.DownloadStep__form {
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px; 
}

@media screen and (max-width: 1280px) {
    .DownloadStep__preview {
        display: none;
    }
} 

@media screen and (min-width: 1280px) {
    .DownloadStep__preview {
        display: 'block';
    }

    .DownloadStep__preview img {
        width: 200px;
        transform: rotate(-20deg);
        height: 150px;
        object-fit: cover;
    }
} 

@media screen and (min-width: 1280px) {
    .DownloadStep__body {
        display: flex;
        margin: auto;
        padding: 0 60px 0 60px;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        align-content: center;
        grid-gap: 100px;
        gap: 100px;
    }    
}

@media screen and (max-width: 1280px) {
    .DownloadStep__body {
        display: flex;
        margin: auto;
        padding: 20px;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        align-content: center;
        grid-gap: 100px;
        gap: 100px;
    }    
}


@media screen and (min-width: 1280px) {
    .Wizard {
        position: relative;
        width: 80%;
        height: 800px;
        margin: 10px auto;
        background-color: #fff;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
} 

@media screen and (max-width: 1280px) {
    .Wizard {
        position: relative;
        width: 100%;
        background-color: #fff;
        box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
        /* border-radius: 4px; */
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
}
.Footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #f7f7f8;
    padding: 10px 30px;
    justify-content: space-between;
    color: #7d7c83;
    font-size: 13px;
}

.Footer span {
    color: #7d7c83;
}

.Footer__logo {
    display: flex;
    flex-direction: row; 
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
}

@media screen and (max-width: 1280px) {
    .Footer {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #f7f7f8;
        padding: 10px;
        justify-content: space-between;
        color: #7d7c83;
        font-size: 13px;
    }

    .Footer__logo {
        display: flex;
        flex-direction: column; 
        align-items:center;
        justify-content: center;
        grid-gap: 0;
        gap: 0;
    }
}
@media screen and (min-width: 1280px) {
    .Generate {
        display: block;
        padding: 20px;
        align-items: center;
        justify-content: center;
        background-color: #F6F6F6;
        flex: 1 1;
    }
}

@media screen and (max-width: 1280px) {
    .Generate {
        display: block;
        align-items: center;
        justify-content: center;
        background-color: #F6F6F6;
        flex: 1 1;
    }
}
.Profile {
    display: flex;
    flex-direction: row;
    grid-gap: 50px;
    gap: 50px;
    justify-content: center;
}

.Profile__panel {
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px;
    padding: 30px 50px;
}

.Profile__config {
    display: flex;
    flex-direction: column;
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 20px;
    min-width: 200px;
}

.Container {
    padding: 20px;
    flex: 1 1;
}

@media screen and (max-width: 1280px) {
    .Profile {
        display: flex;
        flex-direction: column;
        grid-gap: 20px;
        gap: 20px;
        justify-content: center;
    }

    .Profile__panel {
        display: flex;
        flex-direction: column;
        grid-gap: 10px;
        gap: 10px;
        padding: 20px;
        border-radius: 0;
    }
    

    .Container {
        padding: 0 !important;
        flex: 1 1;
    }

    .Profile__config {
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        border: none;
        padding: 20px;
        width: 100%;
        border-radius: 0 !important;
        box-shadow: 0px 2px 2px rgba(0,0,0,0.25) !important;
    }

}
.DownloadButton {
    background-color: #FF9800;
    color: #fff;
    border: none;
    outline: none;
    border-radius: 8px;
    font-size: 14px;
    padding: 10px 12px;
    font-family: 'Inter', sans-serif;
    font-weight: bold;
    margin: 5px;
}

.DownloadButton:hover {
    background-color: #f0ab00;
}

.DownloadButton--deactived {
    background-color: gray !important;
}

.DownloadButton--deactived:hover {
    background-color: gray !important;
}

@media screen and (max-width: 1280px) {
    .DownloadButton {
        margin: 0;
    }
}
.Order {
    display: grid;
    grid-template-columns: 1fr 1fr 2fr 1fr 1fr 1fr;
    align-items: center;
    justify-content: space-between;
    grid-gap: 20px;
    gap: 20px;
}

.Order__thumbnail {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.Order__thumbnail img {
    width: 96px;
    height: 96px;
    object-fit: cover;
}

.Order__section__title {
    font-family: 'Inter';
    font-size: 14px;
}


.Order__section__description {
    font-family: 'Inter';
    font-size: 18px;
}


@media screen and (max-width: 1280px) {
    .Order {
        display: flex;
        flex-direction: column;
        align-items: center;
        border: 1px solid rgba(51, 51, 51, 0.2);
        padding: 20px 0;
    }
    .Order__thumbnail img {
        width: 200px;
        height: 200px;
        object-fit: cover;
    }
    
    .Order__section__title {
        font-size: 14px;
    }
    
    
    .Order__section__description {
        font-size: 14px;
    }

    .Order__status {
        display: flex;
        flex-direction: row;
        grid-gap: 5px;
        gap: 5px;
        justify-content: space-between;
        align-items: center;
    }

    .Order__action {
        display: flex;
        flex-direction: column;
        grid-gap: 10px;
        gap: 10px;
        align-items: center;
        justify-content: center;
    }
}
@media screen and (min-width: 1280px) {
    .Landing-hero-steps {
        background-color: #EAEAEA; 
        height: 100%;
        display: flex; 
        flex-direction: column; 
        padding: 0 10%;
        justify-content: center;
        grid-gap: 50px;
        gap: 50px;
    }
    .Landing-hero {
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;
        height: 100%;
    }
    .Landing-hero-text {
        padding: 0 200px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        grid-gap: 20px;
        gap: 20px;
    }

    .Landing-hero-text-title {
        font-size: 48px;
        font-weight: bold;
        font-family: 'Inter';
        text-align: center;
    }

    .Landing-hero-text-subtitle {
        color: #7D7C83;
        font-size: 18px;
        font-family: 'Inter';
        text-align: center;
    }
}

@media screen and (max-width: 1280px) {

    .Landing-hero-steps {
        display: none;
    }

    .Landing-hero {
        display: grid;
        grid-template-columns: 1fr;
        align-items: center;
        height: 100%;
    }

    .Landing-hero-text {
        padding: 0 5px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        grid-gap: 20px;
        gap: 20px;
    }

    .Landing-hero-text-title {
        font-size: 36px;
        font-weight: bold;
        font-family: 'Inter';
        text-align: center;
    }

    .Landing-hero-text-subtitle {
        color: #7D7C83;
        font-size: 16px;
        font-family: 'Inter';
        text-align: center;
    }
}
