@media screen and (min-width: 1280px) {
    .Generate {
        display: block;
        padding: 20px;
        align-items: center;
        justify-content: center;
        background-color: #F6F6F6;
        flex: 1;
    }
}

@media screen and (max-width: 1280px) {
    .Generate {
        display: block;
        align-items: center;
        justify-content: center;
        background-color: #F6F6F6;
        flex: 1;
    }
}