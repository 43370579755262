.Profile {
    display: flex;
    flex-direction: row;
    gap: 50px;
    justify-content: center;
}

.Profile__panel {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 30px 50px;
}

.Profile__config {
    display: flex;
    flex-direction: column;
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 20px;
    min-width: 200px;
}

.Container {
    padding: 20px;
    flex: 1;
}

@media screen and (max-width: 1280px) {
    .Profile {
        display: flex;
        flex-direction: column;
        gap: 20px;
        justify-content: center;
    }

    .Profile__panel {
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 20px;
        border-radius: 0;
    }
    

    .Container {
        padding: 0 !important;
        flex: 1;
    }

    .Profile__config {
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        border: none;
        padding: 20px;
        width: 100%;
        border-radius: 0 !important;
        box-shadow: 0px 2px 2px rgba(0,0,0,0.25) !important;
    }

}