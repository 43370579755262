
.breadcrumb__item__index--active {
    background-color: #1976D2 !important;
}

@media screen and (min-width: 1280px) {
    .breadcrumb__item__text {
        margin-left: 10px;
        font-family: 'Inter';
        font-size: 18px;
    }

    .breadcrumb__item {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin: 10px;
    }

    .breadcrumb__item__index {
        background-color: #C4C4C4;
        font-family: 'Inter';
        font-size: 18px;
        color: white;
        width: 30px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        border-radius: 100%;
    }

}

@media screen and (max-width: 1280px) {
    .breadcrumb__item__text {
        margin-left: 5px;
        font-family: 'Inter';
        font-size: 14px;
        text-align: center;
    }

    .breadcrumb__item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .breadcrumb__item__index {
        background-color: #C4C4C4;
        font-family: 'Inter';
        font-size: 18px;
        color: white;
        width: 30px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        border-radius: 100%;
    }

}