.Footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #f7f7f8;
    padding: 10px 30px;
    justify-content: space-between;
    color: #7d7c83;
    font-size: 13px;
}

.Footer span {
    color: #7d7c83;
}

.Footer__logo {
    display: flex;
    flex-direction: row; 
    align-items: center;
    gap: 10px;
}

@media screen and (max-width: 1280px) {
    .Footer {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #f7f7f8;
        padding: 10px;
        justify-content: space-between;
        color: #7d7c83;
        font-size: 13px;
    }

    .Footer__logo {
        display: flex;
        flex-direction: column; 
        align-items:center;
        justify-content: center;
        gap: 0;
    }
}