.Gallery {
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
    overflow-x: auto;
    flex-wrap: nowrap;
}

.Gallery__item {
    border: 1px solid rgba(0, 0, 0, 0.5);
}

.Gallery__item__img {
    width: 150px;
    height: 150px;
    object-fit: cover;
}

@media screen and (max-width: 1280px) {
    .Gallery__item__img {
        width: 100px;
        height: 100px;
    }
}