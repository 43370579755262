.breadcrumb {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
}

.breadcrumb__arrow {
    width: 30px;
    margin-left: 10px;
    margin-right: 10px;
}

@media screen and (max-width: 1280px) {
    .breadcrumb {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .breadcrumb__arrow {
        width: 20px;
        margin-left: 10px;
        margin-right: 10px;
    }
}