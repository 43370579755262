@media screen and (min-width: 1280px) {
    .Landing-hero-steps {
        background-color: #EAEAEA; 
        height: 100%;
        display: flex; 
        flex-direction: column; 
        padding: 0 10%;
        justify-content: center;
        gap: 50px;
    }
    .Landing-hero {
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;
        height: 100%;
    }
    .Landing-hero-text {
        padding: 0 200px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 20px;
    }

    .Landing-hero-text-title {
        font-size: 48px;
        font-weight: bold;
        font-family: 'Inter';
        text-align: center;
    }

    .Landing-hero-text-subtitle {
        color: #7D7C83;
        font-size: 18px;
        font-family: 'Inter';
        text-align: center;
    }
}

@media screen and (max-width: 1280px) {

    .Landing-hero-steps {
        display: none;
    }

    .Landing-hero {
        display: grid;
        grid-template-columns: 1fr;
        align-items: center;
        height: 100%;
    }

    .Landing-hero-text {
        padding: 0 5px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 20px;
    }

    .Landing-hero-text-title {
        font-size: 36px;
        font-weight: bold;
        font-family: 'Inter';
        text-align: center;
    }

    .Landing-hero-text-subtitle {
        color: #7D7C83;
        font-size: 16px;
        font-family: 'Inter';
        text-align: center;
    }
}