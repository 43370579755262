.Order {
    display: grid;
    grid-template-columns: 1fr 1fr 2fr 1fr 1fr 1fr;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
}

.Order__thumbnail {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.Order__thumbnail img {
    width: 96px;
    height: 96px;
    object-fit: cover;
}

.Order__section__title {
    font-family: 'Inter';
    font-size: 14px;
}


.Order__section__description {
    font-family: 'Inter';
    font-size: 18px;
}


@media screen and (max-width: 1280px) {
    .Order {
        display: flex;
        flex-direction: column;
        align-items: center;
        border: 1px solid rgba(51, 51, 51, 0.2);
        padding: 20px 0;
    }
    .Order__thumbnail img {
        width: 200px;
        height: 200px;
        object-fit: cover;
    }
    
    .Order__section__title {
        font-size: 14px;
    }
    
    
    .Order__section__description {
        font-size: 14px;
    }

    .Order__status {
        display: flex;
        flex-direction: row;
        gap: 5px;
        justify-content: space-between;
        align-items: center;
    }

    .Order__action {
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: center;
        justify-content: center;
    }
}